<template>
    <list-condition-template ref="table" :rows="rows" :total="total" @page-info-change="handlePageInfoChange" :defaultSort="{prop:'updateTime',order:'descending'}"  v-model:listQuery="listQuery" :index-column="true">
    <template #condition>
      <el-form-item  label="筛选日期：">
        <el-date-picker v-model="date" type="daterange" start-placeholder="开始日期" end-placeholder="结束日期"  range-separator="至" :editable="false" :clearable="false"/>
      </el-form-item>
      <el-form-item>
          <el-select @change="handleFilter" clearable style="margin-left: 10px; width: 150px" class="filter-item" placeholder="审核状态" v-model="listQuery.condition.status">
            <el-option v-for="item in statusOptions" :key="item.name" :label="item.type" :value="item.name">
            </el-option>
          </el-select>
      </el-form-item>
      <el-form-item>
        <el-input @keyup.enter="handleFilter" placeholder="制单人或送办人" v-model="listQuery.condition.keyword" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button class="filter-item" style="margin-left: 10px;" type="primary" v-waves icon="el-icon-search" @click="handleFilter">查询</el-button>
      </el-form-item>

    </template>
    <template #columns>
      <el-table-column min-width="150px" label="流程进度" align="center" property="status">
        <template  #default="scope">
          <el-tag :type="showFilter(scope.row)" >{{scope.row.status&& scope.row.status.type}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column min-width="150px" label="单据类型" align="center">
          <span>标签申请单</span>
      </el-table-column>
      <el-table-column min-width="150px" label="标签申请人" align="center" property="creatorName">
        <template  #default="scope">
          <span>{{scope.row.creatorName}}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="150px" label="更新时间" align="center" property="updateTime">
        <template  #default="scope">
          <span>{{scope.row.updateTime}}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="150px" align="center" label="申请标签（套）" property="labelCount">
        <template  #default="scope">
          <span>{{scope.row.labelCount}}</span>
        </template>
      </el-table-column>    
      <el-table-column min-width="150px" align="center" label="发布标签（套）" property="releaseCount">
        <template  #default="scope">
          <span>{{scope.row.releaseCount}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="300px" class-name="fixed-width">
        <template  #default="scope">
          <el-button type="primary" size="mini" @click="handleInfo(scope.row)" v-waves>查看</el-button>
          <el-button type="info" size="mini" @click="flowLog(scope.row)" v-waves>流程记录</el-button>
          <el-button size="mini" type="danger" v-if="scope.row.status && scope.row.status.name === 'IN_REVIEW' && account === scope.row.createBy" @click="onClickStopButton(scope.row)" v-waves>终止流程</el-button>
        </template>
      </el-table-column>
    </template>
    <el-dialog v-model="logShow" :show-close="true" center title="流程记录" destroy-on-close :append-to-body="true">
      <component :is="component" :sourceApi="myBillApi" :formData="formData" :isComplete="isComplete"></component>
    </el-dialog>
    <el-dialog v-model="terminationShow" title="终止流程">
      <el-form ref="termination" :model="termination">
          <el-form-item label="终止原因:" prop="deleteReason" :rules="[{required: true, message: '终止原因不能为空',trigger:'blur'}]">
              <el-input type="textarea" rows="2" v-model="termination.deleteReason"></el-input>
          </el-form-item>
          <el-form-item>
              <el-button size="mini" @click="onCancelButtonClick">取消</el-button>
              <el-button size="mini" type="primary" @click="onSubmitButtonClick(false)" :loading="termination.loading">{{termination.loading ? '提交中' : '确定'}}</el-button>
          </el-form-item>
      </el-form>
      </el-dialog>
  </list-condition-template>
</template>

<script>
import dayjs from 'dayjs'
import myBillApi from '@/apis/apply/myBill'
import flowHistoryForm from './flowHistory'
import { mapGetters } from "vuex"
import waves from '@/directive/waves' // 水波纹指令
import listConditionTemplate from '@/components/templates/listConditionTemplate'

export default {
  data () {
    return {
      statusOptions: [
        {
          name: 'IN_REVIEW',
          type: '审核中'
        },
        {
          name: 'APPROVED',
          type: '审核通过'
        },
        {
          name: 'TERMINATION',
          type: '终止审核'
        }
      ],
      myBillApi,
      rows: [],
      total: 0,
      listQuery: {
        condition: {
          keyword: undefined,
          startTime:undefined,
          endTime:undefined,
          status: undefined
        },
        pageInfo:{
          pageIndex:1,
          pageSize:20,
          orders: []
        }
      },
      date:[dayjs().subtract(1, 'month'),dayjs()],
      histories:[],
      component:null,
      formData:null,
      logShow:false,
      isComplete:false,
      terminationShow: false,
      termination:{
        loading:false,
        deleteReason:null,
        id:null,
        processInstanceId:null,
        processDefinitionKey: null
      }
    }
  },
  components:{ listConditionTemplate, flowHistoryForm},
  computed:{
    ...mapGetters(["account"])
   },
  watch:{

  },
  mounted () {
  },
  methods: {
    onClickDeleteButton(row){},
    onSubmitButtonClick(){
      this.$refs.termination.validate().then(response => {
          if(response){
              this.$confirm('终止后无法恢复,确定要终止该流程吗？', '警告', {
                  type: 'warning'
              }).then(() => {
                  myBillApi.deleteWorkflow(this.termination).then(res => {
                      if(res.data){
                          this.$message.success('终止流程成功')
                          this.terminationShow = false
                          this.$refs.termination.resetFields()
                          this.getList()
                      }
                  })
              })
          }
      })
    },
    onCancelButtonClick(){
      this.terminationShow = false
      this.$refs.termination.resetFields()
    },
    onClickStopButton(row){
      this.terminationShow = true
      this.termination.id = row.id
      this.termination.processInstanceId = row.processInstanceId,
      this.termination.processDefinitionKey = 'CreateLabel'
    },
    showFilter (row) {
      if(row.status&&row.status.name==='APPROVED'){
        return 'success'
      }else if(row.status&&row.status.name==='IN_REVIEW'){
        return 'primary'
      }else if(row.status&&row.status.name==='TERMINATION'){
        return 'danger'
      }else {
        return 'info'
      }
      
    },
    /**
     * 搜索条件变更处理
     */
    handleFilter () {
      this.getList()
    },
        /**
     * 加载数据
     */
    getList () {
      this.listQuery.condition.startTime = dayjs(this.date[0]).format('YYYY-MM-DD 00:00:00')
      this.listQuery.condition.endTime = dayjs(this.date[1]).format('YYYY-MM-DD 23:59:59')
      myBillApi
        .getPaged(this.listQuery)
        .then(response => {
          this.rows = response.data.content
          this.total = response.data.totalElements
        })
    },
    handlePageInfoChange(){
       this.getList()
    },
    handleInfo(row){
      this.$router.push({path:`/business/cooperation/apply/billInfo/${row.id}`, query: { source:'myBills'}})
    },
    flowLog(row){
      this.logShow = true
      this.isComplete = row.status&&row.status.name==='APPROVED'
      this.formData=row
      this.component = flowHistoryForm
    }
  }
}
</script>

<style>

</style>