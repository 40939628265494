import api from '../api'
/**
 * 标签申请-我的单据
 */
export class MyBillApi extends api {
  /**
   * 查询流程历史记录
   * @param {*} form
   * @returns
   */
   getHistories(bussinessKey) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/workflow/history/${bussinessKey}`,
      method: 'get'
    })
  }
  /**
   * 删除工作流
   * @param {*} data 
   */
  deleteWorkflow(data){
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/workflow/delInstance`,
      method: "post",
      data
    })
  }
}

export default new MyBillApi('labelApply/myBills')